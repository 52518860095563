<template>
  <button class="switch">
    <BaseIcon class="icon" name="ion:apps-sharp" />
    <span class="text">{{ content.text }}</span>
  </button>
</template>

<script lang="ts" setup>
import { ListType } from '@stores/globalStore';

const { t } = useI18n();
const props = defineProps<{
  modelValue: ListType;
}>();

const content = computed(() => {
  return props.modelValue == ListType.LIST
    ? {
        icon: 'ion:apps-sharp',
        text: t('list.standard.title.switch.tile'),
      }
    : {
        icon: 'ion:list',
        text: t('list.standard.title.switch.list'),
      };
});
</script>

<style src="./Switch.scss" scoped lang="scss"></style>
