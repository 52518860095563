<template>
  <button v-if="localStorageAvailable" :aria-label="label" class="like">
    <BaseIcon
      :name="icon"
      :class="{ disliked: icon === 'ion:heart-outline' }"
      class="icon"
    />
  </button>
</template>

<script lang="ts" setup>
import type { Like } from './models';

const { t } = useI18n();

const { config } = defineProps<Like>();

const localStorageAvailable = isLocalStorageAvailable();

const icon = computed(() =>
  config?.isActive ? 'ion:heart' : 'ion:heart-outline'
);
const label = computed(() =>
  config?.isActive
    ? t('components.teaser.box.like.label.remove')
    : t('components.teaser.box.like.label.add')
);
</script>

<style src="./Like.scss" scoped lang="scss"></style>
