<template>
  <div class="text">
    <TeaserListTextTitle :content="title?.content" :config="title?.config" />

    <BaseWhlLink
      v-if="config?.linkTo"
      :to="config.linkTo"
      class="link"
      save-position
      >{{ t('common.more') }}
    </BaseWhlLink>

    <TeaserListTextInfoTags v-if="infoTags" :content="infoTags.content" />

    <TeaserListTextDatelist v-if="datelist" :content="datelist.content" />
  </div>
</template>

<script lang="ts" setup>
import type { Text } from './models';

const { t } = useI18n();

const { config, infoTags, datelist, title } = defineProps<Text>();
</script>

<style src="./Text.scss" scoped lang="scss"></style>
