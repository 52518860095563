<template>
  <div class="buttons">
    <ClientOnly>
      <TeaserListButtonsLike
        v-if="widgetConfig?.quickAccessList && isLocalStorageAvailable()"
        :content="like?.content"
        :config="like?.config"
        :layout="like?.layout"
        @click="emit('toggleLike')"
      />
    </ClientOnly>

    <TeaserListButtonsCloser
      v-if="config?.hasCloser"
      :content="closer?.content"
      :config="closer?.config"
      @click="emit('close')"
    />
  </div>
</template>

<script lang="ts" setup>
import isLocalStorageAvailable from '@utils/isLocalStorageAvailable';
import type { Buttons } from './models';
const widgetConfig = await useWidgetConfig();

const emit = defineEmits(['toggleLike', 'close']);

const { config, like, closer } = defineProps<Buttons>();
</script>

<style src="./Buttons.scss" scoped lang="scss"></style>
