<template>
  <div :class="{ hideSwitchSmall: hideSwitchSmall }" class="header">
    <span v-if="showTitle" class="title">
      {{ title }}
    </span>

    <ListStandardHeaderFilter v-if="whlPageType !== WhlPageType.BOOKMARKS" />

    <ListStandardHeaderSwitch
      v-if="allowedListTypes?.length > 1"
      :model-value="listType"
      @click="emit('switch')"
    />
  </div>
</template>

<script lang="ts" setup>
import { WhlPageType } from '@models/WhlPageType';
import type { ListType } from '@stores/globalStore';
const whlPageType = useWhlPageType();

withDefaults(
  defineProps<{
    title: string;
    showTitle: boolean;
    listType: ListType;
    allowedListTypes?: string[];
    hideSwitchSmall?: boolean;
  }>(),
  {
    hideSwitchSmall: false,
    allowedListTypes: () => [],
  }
);

const emit = defineEmits(['switch']);
</script>

<style src="./Header.scss" scoped lang="scss"></style>
