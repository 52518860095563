<template>
  <div class="teaserList">
    <div class="layout">
      <div class="fig placeholder"></div>

      <div class="text">
        <div class="title placeholder"></div>
        <div class="placeholdertext placeholder"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style src="./../List.scss" scoped lang="scss"></style>
<style src="./Placeholder.scss" scoped lang="scss"></style>
