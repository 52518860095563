<template>
  <div v-if="!isEmpty(content?.items)" class="datelistWrap">
    <span class="info">{{
      t('components.teaser.list.text.datelist.moreDates')
    }}</span>

    <ul class="datelist">
      <li v-for="(item, index) in displayedItems" :key="index" class="item">
        <div>{{ item.text }}</div>
      </li>
      <li v-if="additionalCount > 0" class="item">
        <div>...</div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { Datelist } from './models';
import isEmpty from '@utils/isEmpty';

const { t } = useI18n();

const { content } = defineProps<Datelist>();

const displayedItems = computed(() => {
  return content?.items?.slice(0, 3) ?? [];
});

const additionalCount = computed((): number => {
  const itemsLength = content?.items?.length ?? 0;
  const maxDisplayItems = 3;

  return itemsLength > maxDisplayItems ? itemsLength - maxDisplayItems : 0;
});
</script>

<style src="./Datelist.scss" scoped lang="scss"></style>
