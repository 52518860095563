<template>
  <div class="wrap">
    <ul class="tile">
      <template
        v-if="globalStore.state.loadingState !== LoadingState.FILTERING"
      >
        <li v-for="item in items" class="item">
          <TeaserList
            :content="item.content"
            :config="item.config"
            :fig="item.fig"
            :text="item.text"
            :buttons="item.buttons"
            :banner="item.banner"
          />
        </li>
      </template>

      <template v-if="globalStore.state.loadingState !== LoadingState.LOADED">
        <li v-for="placeholder in 10" class="item">
          <TeaserListPlaceholder>{{ placeholder }}</TeaserListPlaceholder>
        </li>
      </template>
    </ul>

    <ListFooter v-if="showFooter" />
  </div>
</template>

<script lang="ts" setup>
import type { List } from '../models';
import { LoadingState } from '@stores/globalStore';
const globalStore = useGlobalStore();

const props = defineProps<{
  content: List;
  showFooter: boolean;
}>();

const items = computed(() => props.content.items);
</script>

<style src="./Tile.scss" scoped lang="scss"></style>
