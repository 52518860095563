<template>
  <button class="reset" @click="searchStore.resetState()">
    {{ t('components.list.standard.header.filter.reset.button') }}
  </button>
</template>

<script setup lang="ts">
const { t } = useI18n();
const searchStore = useSearchStore();
</script>

<style src="./Reset.scss" scoped lang="scss"></style>
