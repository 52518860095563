<template>
  <div v-show="searchStore.hasAnyValue" class="filter">
    <ListStandardHeaderFilterHead />
    <ListStandardHeaderFilterReset />
    <ListStandardHeaderFilterList />
  </div>
</template>

<script lang="ts" setup>
const searchStore = useSearchStore();
</script>

<style src="./Filter.scss" scoped lang="scss"></style>
