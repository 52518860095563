<template>
  <button
    :aria-label="t('components.teaser.list.buttons.closer.label')"
    class="closer"
  >
    <BaseIcon class="icon" name="ion:close" />
  </button>
</template>

<script lang="ts" setup>
import type { Closer } from './models';

const { t } = useI18n();

defineProps<Closer>();
</script>

<style src="./Closer.scss" scoped lang="scss"></style>
