<template>
  <article :class="{ highlight: layout?.isHighlighted }" class="teaserList">
    <div class="layout">
      <TeaserListFig v-if="fig" :content="fig.content" :config="fig?.config" />

      <div class="right">
        <TeaserListText
          :content="text?.content"
          :config="text?.config"
          :layout="text?.layout"
          :title="text?.title"
          :info-tags="text?.infoTags"
          :datelist="text?.datelist"
        />

        <TeaserListButtons
          :content="buttons?.content"
          :config="buttons?.config"
          :layout="buttons?.layout"
          :like="like"
          :closer="buttons?.closer"
          @toggle-like="toggleLike"
          @close="emit('close')"
        />
      </div>

      <TeaserListBanner
        v-if="banner"
        :layout="banner.layout"
        :content="banner.content"
      />
    </div>
  </article>
</template>

<script lang="ts" setup>
import type { Like } from './Buttons/Like/models';
import type { List } from './models';

const bookmarkStore = useBookmarkStore();

const emit = defineEmits(['close']);

const { config, fig, text, buttons, banner } = defineProps<List>();

const itemKey = computed(() => `${config?.moduleType}_${config?.id}`);

const isLiked = computed(() => {
  if (!config?.moduleType || !config?.id) return false;

  return bookmarkStore.contains(toValue(itemKey));
});

const toggleLike = () => {
  if (!config?.moduleType || !config?.id) return;

  if (isLiked.value) {
    bookmarkStore.removeBookmark(toValue(itemKey));
  } else {
    bookmarkStore.addBookmark(toValue(itemKey));
  }
};

const like = computed(
  (): Like => ({
    content: buttons?.like?.content,
    config: { ...buttons?.like?.config, isActive: isLiked.value },
    layout: buttons?.like?.layout,
  })
);
</script>

<style src="./List.scss" scoped lang="scss"></style>
